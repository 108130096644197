import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'

const InfoBox = () => {

  return (
      <div className="custom-banner">
        <div className="info-box">
          <FontAwesomeIcon className="FA-Icon" icon={faLocationDot} />
          <p className="info-text">MourrOsteo</p>
          <p className="info-text">Chaussée d'Ixelles 29</p>
          <p className="info-text">1050 Ixelles</p>
        </div>
        <div className="info-box">
          <FontAwesomeIcon className="FA-Icon" icon={faClock} />
          <p className="info-text">{"Lundi : 14h - 20h"}</p>
          <p className="info-text">{"Mardi : 8h - 13h"}</p>
          <p className="info-text">{"Mercredi : 11h - 19h"}</p>
          <p className="info-text">{"Jeudi : 14h - 18h"}</p>
          <p className="info-text">{"Vendredi : 8h - 15h"}</p>
        </div>
      </div>
  )
}

export default InfoBox
