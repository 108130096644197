import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {

  return (
    <div className="address-map-container" id="contact">
      <div
        className="address-map"
        dangerouslySetInnerHTML={{
          __html: `<div style="width: 100%"><iframe width="700" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=chauss%C3%A9e%20d'ixelles%2029+(MourrOsteo)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure distance on map</a></iframe></div>`,
        }}
      />
      <div className="contact">
        <a
          className="custom-button"
          style={{ marginRight: 0, marginBottom: "20px" }}
          href="https://www.doctoranytime.be/d/kinesitherapeute/gabrielle-doorenbos"
        >
          <button>
            <span
              className="bi bi-calendar-check"
              style={{ paddingRight: "10px" }}
            ></span>
            Prendre rendez-vous
          </button>
        </a>
        <p className="contact-text" style={{ color: "white" }}>
          <i className="bi-phone"></i> 0496 32 52 37
        </p>
        <div className="contact-box">
          <FontAwesomeIcon
            className="FA-Icon"
            icon={faLocationDot}
            style={{ color: "white" }}
          />
          <p className="contact-text">MourrOsteo</p>
          <p className="contact-text">Chaussée d'Ixelles 29</p>
          <p className="contact-text">1050 Ixelles</p>
        </div>
        <div className="contact-box">
          <FontAwesomeIcon
            className="FA-Icon"
            icon={faClock}
            style={{ color: "white" }}
          />
          <p className="contact-text">{"Lundi : 14h - 20h"}</p>
          <p className="contact-text">{"Mardi : 8h - 13h"}</p>
          <p className="contact-text">{"Mercredi : 11h - 19h"}</p>
          <p className="contact-text">{"Jeudi : 14h - 18h"}</p>
          <p className="contact-text">{"Vendredi : 8h - 15h"}</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
