import * as React from "react";
//@ts-ignore
import gab from "./../../images/moi.jpeg";

const About = () => {

  return (
    <div>
      <section className="section-padding" id="about">
        <div className="container">
          <div className="row">

            <div className="col-lg-8 col-md-6 col-12">
              <h2 className="mb-lg-3 mb-3">Moi</h2>

              <p>Kinésithérapeute depuis 2021, j’ai complété ma formation de base par des formations en posturologie, posturologie dynamique, marche nordique ainsi que dans la prise en charge de la douleur chronique.</p>

              <p>Je m’appuie sur les dernières études scientifiques, ma pratique et mes connaissances afin de construire une prise en charge globale, adaptée à chaque personne et à sa pathologie. Le patient est actif dans sa rééducation dont il est l’acteur principal et nous travaillons ensemble vers l’objectif établi et la guérison.</p>

              <p>Mon travail au sein du centre multidisciplinaire de la douleur chronique du CHU-UCL Mont-Godinne m’a apporté une connaissance approfondie de la prise en charge de la douleur persistante et de l’application du modèle bio-psychosocial. </p>

              <p>Mes pratiques en aïkido, yoga, pilates et escalade me permettent d’offrir un large panel d’exercices pour la rééducation et la réadaptation des athlètes de tout niveau dans leur mouvement sportif.</p>

              <p>En plus de la kinésithérapie en cabinet, je suis diplômée professeure de yoga afin d’avoir une approche complémentaire dans la compréhension du mouvement. Je prends également en charge les femmes en yoga pré et post-natal dans la préparation à l’accouchement, le soulagement des douleurs liées à la grossesse, le travail de la respiration, la prise de conscience corporelle et la récupération en douceur.</p>
            </div>

            <div className="col-lg-4 col-md-5 col-12 mx-auto" style={{ position: "relative" }}>
              <div className="circle"></div>
              <div className="featured-circle d-flex justify-content-center align-items-center">

                <img className="img-thumbnail shadow-lg" src={gab} style={{ width: "70%" }} alt="Photo de moi" />
              </div>
            </div>

          </div>
        </div>
      </section>

    </div>
  )
}

export default About
